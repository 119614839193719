
@import url(https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);

/* * {
  font-family: "Spoqa Han Sans !important", "Spoqa Han Sans JP", "Sans-serif";
} */

/* Google Map에 지도 외에 제거 */
.gm-fullscreen-control,
.gm-style-mtc,
.gm-svpc,
.gm-bundled-control {
  display: none;
}
.gm-style a {
  display: none !important;
}
.gm-style a img {
  display: none;
}
.gm-style button {
  display: none;
}

/* slide 기능의 dot부분 스타일 */
ul.slick-dots {
  bottom: -30px !important;
}
ul.slick-dots > li {
  width: 15px;
  height: 15px;
  margin: 0 !important;
}
ul.slick-dots > li button {
  width: 15px;
  height: 15px;
}
ul.slick-dots > li button::before {
  width: 7px !important;
  height: 7px !important;
  background-color: lightgray !important;
  color: transparent !important;
  content: "" !important;
}
ul.slick-dots > li.slick-active button::before {
  width: 7px !important;
  height: 7px !important;
  background-color: #315ffc !important;
  color: transparent !important;
  content: "" !important;
}
div.slick-prev:hover {
  background-color: #5d6d6f;
  color: #fff;
}
div.slick-next:hover {
  background-color: #5d6d6f;
  color: #fff;
}

.stylizedHelper {
  color: #000 !important;
  width: 100% !important;
  display: inline-block;
  background: #ddd;
  vertical-align: sub;
  padding: 0 !important;
  line-height: 36px;
  font-weight: 700;
}

.stylizedHelper div {
  display: inline-block;
  margin: 0 5px;
}

.stylizedHelper div:nth-child(1) {
  width: 30%;
  margin-left: 10px;
}
